import axios from 'axios'
import store from './store'

export default class ApiClient {
  baseUrl: string
  mainUrl: string

  constructor(url: string, main: string) {
    this.baseUrl = url + '/api'
    this.mainUrl = main + '/api'
  }

  login(authData: any) {
    return axios.post(`${this.mainUrl}/login`, authData)
  }

  getShops(params: any) {
    return axios.get(`${this.baseUrl}/shops`, { params, headers: this.getHeaders() })
  }

  getShop(id: string) {
    return axios.get(`${this.baseUrl}/shops/${id}`, { headers: this.getHeaders() })
  }

  saveShops(shops: any) {
    return axios.post(`${this.baseUrl}/shops/bulk`, shops, { headers: this.getHeaders() })
  }

  putShop(shop: any) {
    return axios.put(`${this.baseUrl}/shops/${shop._id}`, shop, { headers: this.getHeaders() })
  }

  getShopProducts(id: string, params?: any) {
    return axios.get(`${this.baseUrl}/shops/${id}/products`, { params, headers: this.getHeaders() })
  }

  getShopSales(id: string, params?: any) {
    return axios.get(`${this.baseUrl}/shops/${id}/sales`, { params, headers: this.getHeaders() })
  }

  getTags(query?: string): Promise<any> {
    return axios.get(`${this.baseUrl}/tags`, { params: { query }, headers: this.getHeaders() })
  }

  postTag(tag: any) {
    return axios.post(`${this.baseUrl}/tags`, tag, { headers: this.getHeaders() })
  }

  removeTag(tagId: string) {
    return axios.delete(`${this.baseUrl}/tags/${tagId}`, { headers: this.getHeaders() })
  }

  startImageSearch(data: {
    urls: string[]
    title: string
    sourceUrl: string
    sourceId: string
    specs?: any
    lang?: string
  }) {
    return axios.post(`${this.mainUrl}/products/matching-images`, data, { headers: this.getHeaders() })
  }

  linkImageSearch(productId: string, imageSearchId: string) {
    return axios.put(
      `${this.baseUrl}/products/${productId}`,
      { imageSearch: imageSearchId },
      { headers: this.getHeaders() }
    )
  }

  getShopsXLS(params: any) {
    return axios({
      url: `${this.baseUrl}/shopsXls`,
      method: 'GET',
      responseType: 'blob',
      params,
      headers: this.getHeaders()
    })
  }

  async refresh(data: any = {}): Promise<any> {
    // prettier-ignore
    const headers = { 'x-refresh-token': store.state.refreshToken, 'x-access-token': store.state.token }
    return axios.post(this.mainUrl + '/refresh-token', data, { headers })
  }

  private getHeaders() {
    const token = store.state.token
    return { 'x-access-token': token }
  }
}
