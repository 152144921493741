
import { Component, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class App extends Vue {
  loader: any = null
  loading = false

  @Watch('loader')
  setLoading() {
    if (this.loader === 'loading') {
      this.loading = true
    } else {
      this.loading = false
      this.loader = null
    }
  }

  get isAuthenticated() {
    return this.$store.getters.isAuthenticated
  }

  logout() {
    this.$store.dispatch('logout')
    this.$router.push({ name: 'Home' })
  }

  importFile() {
    this.$router.push({ name: 'file-upload' })
  }

  get params() {
    return this.$store.state.params
  }

  get routeName() {
    return this.$route.name
  }

  async exportXLS() {
    try {
      this.loader = 'loading'
      const params = { domain: this.params.domain, searchTags: this.params.searchTags, status: this.params.status }
      const response = await this.$store.state.apiClient.getShopsXLS(params)

      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      const fname = 'shops-' + moment().format('YYYY-MM-DD') + '.xlsx'
      link.setAttribute('download', fname)
      document.body.appendChild(link)
      link.click()
      this.loader = ''
    } catch (err) {
      console.log('error: ', err)
      this.loader = ''
    }
  }
}
