
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Home extends Vue {
  private password = ''
  private email = ''
  show = false
  snackbar = false

  rules = {
    required: (value: string): boolean | string => !!value || 'Required.',
    min: (v: string): boolean | string => v.length >= 4 || 'Min 4 characters'
  }

  get loginDisabled(): boolean {
    const noPassword = !this.password || !this.password.length || this.password.length < 4
    const noUser = !this.email || !this.email.length || this.email.length < 8
    return noPassword || noUser
  }

  async login(): Promise<void> {
    if (this.loginDisabled) return

    try {
      await this.$store.dispatch('login', { password: this.password, email: this.email })
      this.$router.push({ name: 'Dashboard' })
    } catch (error) {
      this.snackbar = true
    }
  }
}
