import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import Vue from 'vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import 'echarts/lib/chart/bar'
// import 'echarts/lib/chart/line'
// import 'echarts/lib/chart/pie'
// import 'echarts/lib/component/dataZoom'
// import 'echarts/lib/chart/funnel'
// import 'echarts/lib/component/tooltip'
// import 'echarts/lib/component/legend'
// import 'echarts/lib/component/title'
// import 'echarts/lib/component/toolbox'
// import 'echarts/lib/component/legend'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h: any) => h(App)
} as any).$mount('#app')
