import ApiClient from '../api-client'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const { VUE_APP_API, VUE_APP_BASE_API } = process.env

const apiClient = new ApiClient(VUE_APP_API, VUE_APP_BASE_API)

const initialState = {
  apiClient,
  shops: [],
  tags: [],
  params: {
    page: 1,
    serverItemsLength: 0,
    itemsPerPage: 10,
    domain: '',
    searchTags: '',
    status: '',
    advancedFilters: null,
    authenticated: false
  },
  token: localStorage.getItem('token') || '',
  refreshToken: localStorage.getItem('refreshToken') || ''
}

export default new Vuex.Store({
  state: initialState,
  mutations: {
    login(state, tokens) {
      state.token = tokens.token
      state.refreshToken = tokens.refreshToken
      localStorage.setItem('token', tokens.token)
      localStorage.setItem('refreshToken', tokens.refreshToken)
      localStorage.setItem('issuedDate', Date.now().toString())
    },
    logout(state) {
      state.token = ''
      state.shops = []
      state.params = {
        page: 1,
        serverItemsLength: 0,
        itemsPerPage: 10,
        domain: '',
        searchTags: '',
        status: '',
        advancedFilters: null,
        authenticated: false
      }
    },
    setParams(state, newParams) {
      state.params = newParams
    },
    setShops(state, data) {
      state.shops = data.shops
      state.params.serverItemsLength = data.total
    },
    setTags(state, tags) {
      state.tags = tags
    }
  },
  getters: {
    isAuthenticated: state => {
      const expirationDate = parseInt(localStorage.getItem('issuedDate')) + 3600 * 1000 * 24
      if (Date.now() >= expirationDate || !state.token) return false
      else return true
    }
  },
  actions: {
    async login({ state }, authData: any) {
      const response = await state.apiClient.login(authData)
      if (response.data.user.privileges < 2) throw new Error('Must be an admin.')
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('refreshToken', response.data.refreshToken)
      this.commit('login', { token: response.data.token, refreshToken: response.data.refreshToken })
    },

    async getShops({ state }, params: any) {
      if (params.authenticated) params = { ...params, ...params.advancedFilters }
      delete params.advancedFilters

      const response = await state.apiClient.getShops(params)
      this.commit('setShops', response.data)
    },

    // TODO: fix intellisense not working on declarations
    async putShop({ state }, shop: any) {
      await state.apiClient.putShop(shop)
      // this.commit('setShops', response.data)
    },

    async getTags({ state }, query?: string) {
      const response = await state.apiClient.getTags(query)
      this.commit('setTags', response.data)
      return response.data
    },

    // async removeTag({ state }, shop: any) {
    //   const response = await state.apiClient.removeTag(shop)
    //   // this.commit('setShops', response.data)
    // },

    async postTag({ state }, tag: any): Promise<any> {
      const response = await state.apiClient.postTag(tag)
      this.dispatch('getTags')
      return response.data
    },

    setParams({ state }, newParams: any) {
      this.commit('setParams', newParams)
    },

    async refreshToken() {
      try {
        const response = await this.state.apiClient.refresh()

        if (response.status !== 200) throw new Error('Unauthorized')

        this.commit('login', { token: response.data.token, refreshToken: response.data.refreshToken })
        return response
      } catch (error) {
        this.dispatch('logout')
        Promise.reject(error)
      }
    },

    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('issuedDate')
      this.commit('logout')
    }
  },
  modules: {}
})
