import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/login',
    name: 'Home',
    component: Home
  },
  {
    path: '/app',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/file-upload',
    name: 'file-upload',
    component: () => import(/*webpackChunkName: "file-upload" */ '../views/FileUpload.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/shop/:id',
    name: 'shop',
    component: () => import(/*webpackChunkName: "shop" */ '../views/Shop.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated: boolean = store.getters.isAuthenticated
  if (to.meta.requiresAuth && isAuthenticated) return next()
  if (to.meta.requiresAuth && !isAuthenticated) {
    return store
      .dispatch('refreshToken')
      .then(() => next())
      .catch(() => {
        next({ name: 'Home' })
      })
  }
  if (!to.meta.requiresAuth && isAuthenticated) return next({ name: 'Dashboard' })
  next()
})

export default router
